






























































































































































































































































































import Vue from 'vue';

import TaskDialogContainer from '@/container/task/TaskDialogContainer.vue';
import ChannelCreateContainer from '@/container/channel/ChannelCreateContainer.vue';
import IntercomContainer from '@/container/common/IntecomContainer.vue';
import CaptureContainer from '@/container/app/CaptureContainer.vue';
import { taskDialogModule } from '@/store/viewModule/task/taskDialogModule';
import { tagModule } from '@/store/dataModule/tag/tagModule';
import { commonModule } from '@/store/viewModule/common/commonModule';
import { todayViewModule } from '@/store/viewModule/today/todayViewModule';
import { taskpoolViewModule } from '@/store/viewModule/taskpool/taskpoolViewModule';
import { taskpoolModule } from '@/store/dataModule/report/taskpoolModule';
import { journalViewModule } from '@/store/viewModule/journal/journalViewModule';
import { statsViewModule } from '@/store/viewModule/stats/statsViewModule';
import { journalModule } from '@/store/dataModule/journal/journalModule';
import { userModule } from '@/store/dataModule/user/userModule';
import { channelsModule } from '@/store/dataModule/channel/channelsModule';
import dayjs from 'dayjs';
import { gaSetUserId, sendEvent } from '@/service/GoogleAnalyticsService';

import { getProgressColor } from '@/service/StyleService';
import { teamModule } from '@/store/dataModule/team/teamModule';
import { MyTeamResponse, TeamResponse } from 'remonade-api/lib';
import LocalDataService from '@/service/LocalDataServices';
import { TaskItem } from '@/model/types';

interface DataType {
  isFetchDataCompleted: boolean;
  showRemainTasks: boolean;
}

export default Vue.extend({
  name: 'DesktopAppContainer',

  components: {
    TaskDialogContainer,
    ChannelCreateContainer,
    IntercomContainer,
    CaptureContainer,
  },

  data: (): DataType => {
    return {
      isFetchDataCompleted: false,
      showRemainTasks: true,
    };
  },

  methods: {
    fabClicked() {
      taskDialogModule.setOpen(true);
    },
    logout() {
      commonModule.logout();
    },
    changeTeam(team: MyTeamResponse) {
      LocalDataService.setTeamId(team.teamId);
      LocalDataService.setUserId(team.user.userId);
      gaSetUserId(team.user.userId, team.user.userType);
      location.reload();
    },
    checkReload(event: Event) {
      const document = event.target as Document;
      if (document) {
        if (!document.hidden) {
          userModule.updateLastActive();
          const lastActive = LocalDataService.getWindowActiveTimestamp();
          if (lastActive) {
            const now = dayjs();
            const last = dayjs(lastActive);
            const diff = now.diff(last, 'hour');
            if (diff > 4) {
              location.reload();
            }
          }
        }
        const ts = dayjs().format('YYYY-MM-DD HH:mm:ss');
        LocalDataService.setWindowActiveTimestamp(ts);
      }
    },
    shortenTaskName(taskName?: string) {
      if (taskName) {
        if (taskName.length > 19) {
          return taskName.slice(0, 19) + '..';
        }
        return taskName;
      }
      return '';
    },
    async doneTask(taskItem: TaskItem) {
      const isDone = taskItem.isDone ? !taskItem.isDone : true;
      await taskDialogModule.updateTaskDone({ taskId: taskItem.taskId, isDone });

      sendEvent('task_done_fab', {});
    },
  },

  computed: {
    showLoader() {
      return commonModule.showLoader;
    },
    presenseUsersLength() {
      return todayViewModule.allPresenceUserLength;
    },
    poolsSuggestedToMe() {
      return taskpoolModule.myTasksCounts;
    },
    poolsSuggestedToAnyone() {
      return taskpoolModule.anyoneTasksCounts;
    },
    todayJournal() {
      return journalModule.todayJournal;
    },
    myProgress() {
      return todayViewModule.myTodayProgress * 100;
    },
    myProgressColor() {
      return getProgressColor(todayViewModule.myTodayProgress);
    },
    currentTeam(): TeamResponse | null {
      return teamModule.team;
    },
    lockPage() {
      if (this.$route.name === 'settings') {
        return false;
      }
      if (this.currentTeam) {
        const isFree = this.currentTeam.plan.price === 0;
        if (isFree) {
          return todayViewModule.teamUsers.length > 4;
        }
      }
      return false;
    },
    teamIconTitle(): string | null {
      if (this.currentTeam) {
        return this.currentTeam.name.slice(0, 2).toUpperCase();
      }
      return null;
    },
    myTeams() {
      return commonModule.teams;
    },
    uncompletedTasks() {
      return taskDialogModule.uncompletedTasks;
    },
  },

  async created() {
    document.addEventListener('visibilitychange', this.checkReload);

    const isSessionValid = await commonModule.checkSession();
    
    if (!isSessionValid) {
      this.$router.push({ name: 'signin' });
    }

    await commonModule.fetchAppData();
    this.isFetchDataCompleted = true;

    const user = LocalDataService.getUser();
    if (user) {
      gaSetUserId(user.userId, user.userType);
    }
  },

  beforeDestroy() {
    document.removeEventListener('visibilitychange', this.checkReload);
  },
});
